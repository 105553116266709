<template>
  <div class="violations-modal-edit__header">
    <r-button
      type="success"
      :disabled="disabled"
      @click="$emit('save')"
    >
      {{ $t('modal-edit-header:' + 'register_modal::save-button') }}
    </r-button>
    <r-button
      simple
      mini
      @click="$emit('view')"
    >
      {{ $t('modal-edit-header:' + 'register_modal::cancel-button') }}
    </r-button>
    <r-block
      no-padding
      col
    >
      <r-title type="subtitle-2">
        Устранено
      </r-title>
      <el-switch
        v-model="source.eliminated"
        class="r-switch"
        @change="$emit('hasChanges')"
      />
    </r-block>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss">
.violations-modal-edit__header {
  display: grid;
  grid-gap: 0.5rem;
  align-items: center;
  justify-content: start;
  grid-auto-flow: column;
}
</style>
